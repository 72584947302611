let ROOT_URL = "http://staging.alphonic.net.in:6002/api/v1/v";
if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"
) {
  console.log(1);
  // ROOT_URL ='https://jsonplaceholder.typicode.com'
} else {
  // TODO: Server API URL
  ROOT_URL = "https://doapi.alphonic.net.in/api/v1/v";
  console.log(2);
}
// ROOT_URL = "https://doapi.alphonic.net.in/api/v1/v";
ROOT_URL = "https://api.dadropoff.com/api/v1/v"; //live api added
export default ROOT_URL;
